import { Component, computed, inject, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { Venues } from 'src/app/shared/models/event.model';
import { Inventory } from 'src/app/shared/models/inventory.model';


@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrl: './tickets-list.component.css'
})
export class TicketsListComponent implements OnInit {

  // SERVICES
  private inventoryService: InventoryService = inject(InventoryService);

  // STATE
  public inventory:  Signal<Inventory[] | undefined> = this.inventoryService.getInventory;
  protected filter:  WritableSignal<string>          = signal('ALL'); 
  
  // COMPUTED
  protected venues:  Signal<Venues> = computed(()=> {
    
    const venues: Venues = {'ALL': {name: 'ALL', active: true}};
    
    if(this.inventory()){
      this.inventory()!.forEach(inventory => venues[inventory.venue] = {name: inventory.venue, active: false})
    }

    return venues;

  })

  protected filteredInventory: Signal<Inventory[] | undefined> = computed(() => {
    
    if(this.filter() !== 'ALL'){
      const inventoryFiltered = this.inventory()!.filter(inventory => inventory.venue === this.filter());
      return inventoryFiltered;
    }

    return this.inventory();
  })

  // LIFECYCLE HOOKS
  ngOnInit(): void {
    this.initComponent();
  }

  // METHODS
  protected filterByVenue(venue: keyof Venues): void {
    const activeFilter  = Object.values(this.venues()).filter(venue => venue.active === true)[0];
    const newFilter     = this.venues()[venue];
    
    if(activeFilter.name === newFilter.name){
      this.resetFilters(activeFilter);
      return;
    }

    activeFilter.active = false;
    newFilter.active    = true;
  
    this.filter.set(newFilter.name);
  }

  /**
   * Cambia el estado del filtro actual a false y reestablece el filtro por defecto 'ALL'.
   * Este método solo se llama cuando pulsamos dos veces sobre el mismo filtro y produce un efecto
   * toggle de activar / desactivar el filtro devolviendo al estado original.
   * @param {Venues['index']} activeFilter 
   */
  private resetFilters(activeFilter: Venues['']): void {

    activeFilter.active = false;

    this.venues()['ALL'].active = true;

    this.filter.set('ALL')

  }

  private initComponent(): void {
    this.inventoryService.fetchInventory('All');
  }
  

}
