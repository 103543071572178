<div class="relative min-h-80 p-5">

    <!-- VIEW -->
    <ng-container *ngIf="inventory() else loader">

        <!-- TICKETS -->
        <ng-container *ngIf="inventory()?.length else noTickets">

            <!-- TITULO Y FILTROS -->
            <div class="block">

                <h3 class="text-secondary text-2xl mb-5 lg:my-3 md:text-2xl">
                    My Tickets
                </h3>

                <!-- PILLS / SELECTOR -->
                <div class="hidden md:flex gap-x-2 xl:gap-x-4">
                    <button *ngFor="let venue of venues() | keyvalue" (click)="filterByVenue(venue.value.name)" class="pill" [ngClass]="{'pill-active': venue.value.active}">
                        <span>{{venue.key}}</span>
                    </button>
                </div>

                <div class="md:hidden mb-5 h-11">
                    <app-selector [venues]="venues()" (selected)="filterByVenue($event)"></app-selector>
                </div>

            </div>

            <!-- LISTA DE TICKETS -->
            <div class="md:mt-5 border rounded-2xl p-4 lg:py-6 lg:px-9 xl:py-7 xl:px-11">
                
                <table class="ticket-table w-full">

                    <!-- TABLE HEADER -->
                    <tr class="ticket-table-headers text-left font-main text-main/80 *:font-medium lg:-translate-y-2">
                        
                        <!-- EVENT NAME -->
                        <th class="w-2/6 sm:w-4/12">
                            Event
                        </th>

                        <!-- VENUE -->
                        <th class="hidden md:block md:w-3/12">
                            Venue
                        </th>

                        <!-- DATE -->
                        <th class="w-1/6 sm:w-3/12 lg:w-3/12">
                            Date
                        </th>

                        <!-- VIEW BUTTON -->
                        <th class="w-1/6 lg:w-2/12">
                        </th>

                    </tr>

                    <!-- TABLE ROW TICKETS -->
                    <tr *ngFor="let ticket of inventory()" class="ticket-table-item *:font-semibold *:font-main  sm:*:text-base">
                        
                        <!-- EVENT NAME -->
                        <td class="uppercase text-secondary text-[.6rem]">
                            {{ticket.name}}
                            <span class="block md:hidden text-darkgray/80 text-[.6rem] sm:text-xs">
                                {{ticket.venue}}
                            </span>
                        </td>

                        <!-- VENUE -->
                        <td class="hidden md:table-cell uppercase text-darkgray">
                            {{ticket.venue}}
                        </td>

                        <!-- DATE -->
                        <td class="text-darkgray text-[.6rem]">
                            <div class="md:hidden flex flex-col">
                                <span>
                                    {{ticket.date | date:'d/M/yy'}}
                                </span>
                                <span>
                                    {{ticket.date | date:'h:mm a'}}
                                </span>
                            </div>
                            <span class="hidden md:table-cell">
                                {{ticket.date | date:'d/M/yy h:mm a'}}
                            </span>
                        </td>

                        <!-- VIEW BUTTON -->
                        <td>
                            <button class="button-secondary font-normal">
                                View
                            </button>
                        </td>

                    </tr>


                    <!-- TEST -->
                     

                   

                </table>

            </div>

        </ng-container>

        <!-- NO TICKETS -->
        <ng-template #noTickets>
            <div class="flex items-center justify-center h-80 lg:border lg:rounded-xl">
                <p class="text-secondary font-medium lg:text-xl xl:text-2xl">
                    No tickets available
                </p>
            </div>
        </ng-template>

    </ng-container>

    <!-- LOADER -->
    <ng-template #loader>
        <div class="absolute flex items-center justify-center w-full h-80">
            <app-loader/>
        </div>
    </ng-template>
    
</div>